<template>
  <div class="row mt-60 mb-40" v-show="show_exhibitors">
    <div class="col-md-12">
      <div class="d-flex align-items-center">
        <h3>EXPOSITORES</h3>
        <span class="uc-heading-decoration"></span>
      </div>
    </div>
    <div class="col-md-12 mt-40">
      <carousel
        :autoplay="true"
        :autoplayTimeout="5000"
        :loop="true"
        :perPage="4"
      >
        <slide v-for="(exhibitor, key) in exhibitorLogo" :key="key">
          <div class="exhibitorLogo" :style="getImgUrl(exhibitor)"></div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import _ from "lodash";
import axios from "axios";

export default {
  components: { Carousel, Slide },
  data() {
    return {
      exhibitorLogo: [],
      show_exhibitors: false,
    };
  },
  beforeCreate() {
    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/fairs/status`, {
        withCredentials: false,
      })
      .then((response) => {
        this.show_exhibitors = response.data.show_exhibitors;
        if (!response.data.show_exhibitors) {
          return;
        }

        axios
          .get(`${process.env.VUE_APP_REDES_ENDPOINT}/fairs/exhibitors`, {
            withCredentials: false,
          })
          .then((response) => {
            this.exhibitorLogo = _.shuffle(response.data.data);
          });
      });
  },
  methods: {
    getImgUrl(exhibitor) {
      return `
        background-image: url('${process.env.VUE_APP_REDES_BASE_PATH}/${exhibitor.path}');
        height: ${exhibitor.height}px;
      `;
    },
  },
};
</script>

<style lang="sass">
.VueCarousel-slide
  align-items: center
  display: flex
.exhibitorLogo
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  width: 80%
</style>
